import Request from '../helpers/RequestHelper';
import StorageHelper from '../helpers/StorageHelper';
import jwt from 'jwt-decode';
import HandleRequest from '../handlers/RequestHandler';
import EnvHelper from '../helpers/EnvHelper';
import UserHelper from '../helpers/UserHelper';
import Crypto from 'crypto-js';
import i18n from 'i18next';
import CountryHelper from '@common/classes/helpers/CountryHelper';
import LanguageHelper from '@common/classes/helpers/LanguageHelper';

export const envId = process.env.REACT_APP_WL_ENVIRONMENT_ID ?? null;

const _api = 'users/';
const _apiB3 = EnvHelper.isPrd() ? 'users/' : 'users-b3/';
const nTypePlatformID = 4;
const method = envId ? 'wl-educacional' : 'site-educacional';

export const userApi = {
  async registration(data) {
    const resp = await Request.post(`${_api}create/minimal`, {
      strUserName: data.username,
      bTermsAndPrivacyPolicyRead: true,
      strUserEmail: data.email,
      strUserPassword: data.password,
      strSurname: data.usersurname,
      strCellPhone: data.phoneNumber,
      createAccessToken: true,
      nBillingCountry: data.countryId || CountryHelper.BRAZIL_ID,
      nCountryNationalityID: data.countryId || CountryHelper.BRAZIL_ID,
      nLanguage: LanguageHelper.getLanguageByCode(i18n.language).id,
      method,
      strRecaptchaResponse: data.strRecaptchaResponse,
      strMachineID: UserHelper.getHardwareID().toString(),
      nTypePlatformID,
    }).catch(e => e);
    if (resp.data && resp.data.auth) {
      await this.setTokenOnLocalStorage(resp.data.auth);
      return this.setUserData();
    }
    return resp;
  },
  async passwordRecoveryConfirm(payload) {
    const hashPassword = Crypto.SHA1(payload.strNewPassword);
    const newPassword = `${hashPassword.toString(Crypto.enc.Hex)}`;
    try {
      const resp = await Request.post(`${EnvHelper.isInvestMe() ? _apiB3 : _api}reset-password/confirm`, {
        strResetLink: payload.strResetLink,
        strNewPasswordHash: newPassword,
        strRecaptchaResponse: payload.strRecaptchaResponse,
      });
      return HandleRequest.processResponse(resp);
    } catch (error) {
      return HandleRequest.processResponse(error);
    }
  },
  async passwordRecoveryData(payload) {
    try {
      const resp = await Request.get(`${EnvHelper.isInvestMe() ? _apiB3 : _api}reset-password/${payload}`);
      return HandleRequest.processResponse(resp);
    } catch (error) {
      return HandleRequest.processResponse(error);
    }
  },
  async passwordRecoveryInvestMeData(payload) {
    try {
      const resp = await Request.get(`${EnvHelper.isInvestMe() ? _apiB3 : _api}reset-password-routing/${payload}`);
      return HandleRequest.processResponse(resp);
    } catch (error) {
      return HandleRequest.processResponse(error);
    }
  },
  async passwordRecovery(payload) {
    try {
      const resp = await Request.post(`${EnvHelper.isInvestMe() ? _apiB3 : _api}reset-password`, {
        strUserIdentification: payload.strUserIdentification,
        strRecaptchaResponse: payload.strRecaptchaResponse,
        method,
      });
      return HandleRequest.processResponse(resp);
    } catch (error) {
      return HandleRequest.processResponse(error);
    }
  },

  async passwordRecoveryRouting(payload) {
    const hashPassword = EnvHelper.isInvestMe() ? Crypto.SHA1(payload.strNewPassword) : Crypto.MD5(payload.strNewPassword);
    try {
      const url = EnvHelper.isInvestMe() ? `${_apiB3}reset-password/confirm` : `${_api}reset-password-routing/confirm`;
      const resp = await Request.post(url, {
        strResetLink: payload.strResetLink,
        strNewPasswordHash: EnvHelper.isInvestMe() ? `${hashPassword.toString(Crypto.enc.Hex)}` : `${hashPassword.toString(Crypto.enc.Hex)}`,
        strRecaptchaResponse: payload.strRecaptchaResponse,
      });
      return HandleRequest.processResponse(resp);
    } catch (error) {
      return HandleRequest.processResponse(error);
    }
  },

  async setTokenOnLocalStorage(data) {
    data['timestamp_token'] = Date.now() + data.expires_in * 1000;
    StorageHelper.setToLocalStorage('expires_in', data.expires_in);
    StorageHelper.setToLocalStorage('timestamp_token', data.timestamp_token);
    StorageHelper.setToLocalStorage('access_token', data.access_token);
  },

  async setUserData() {
    const dataToken = jwt(UserHelper.getBearerToken());
    return this.getUserData(dataToken.sub);
  },

  async getUserData(userId) {
    try {
      const resp = await Request.get(`${EnvHelper.isInvestMe() ? _apiB3 : _api}${userId}`, true);
      return HandleRequest.processResponse(resp);
    } catch (error) {
      return HandleRequest.processResponse(error);
    }
  },

  async sendEmailRequest() {
    const resp = await Request.post('/users/change-email-request').catch(e => e);

    return resp;
  },

  async createUser(data) {
    const body = this.buildBody(data);
    const resp = await Request.post(`${_api}create/educational`, { ...body, isFreeCourse: data.isFreeCourse }).catch(e => e);
    if (resp.data && resp.data.auth) {
      await this.setTokenOnLocalStorage(resp.data.auth);
      return this.setUserData();
    }
    return resp;
  },

  async updateUserRegister(data, userId) {
    const body = this.buildBody(data);
    const resp = await Request.patch(`${_api}${userId}/update`, body).catch(e => e);
    return resp;
  },

  async updateUser(data, userId) {
    const body = this.buildBodyUpdate(data);
    const res = await Request.patch(`${_api}${userId}/update`, body).catch(e => e);
    return res;
  },

  async getAllUserData(userId) {
    try {
      const nUserID = Number.parseInt(userId, 10);
      if (!Number.isInteger(nUserID)) {
        throw new Error(`getUser parameter error: ${nUserID}`);
      }
      return await Request.get(`${_api}${nUserID}/all`, true, null);
    } catch (error) {
      return HandleRequest.processResponse(error);
    }
  },

  async validRegister(hash) {
    const resp = await Request.get(`${_api}register/${hash}/verify-email`).catch(e => e);
    return resp;
  },

  async WLContact(data) {
    const resp = await Request.post('/users/product/lead', data).catch(e => e);
    return resp;
  },

  async listMethodologyStudyOptions() {
    const resp = await Request.get(`${_api}list/methodology-study`).catch(e => e);
    return resp;
  },

  async listMethodologyAnalysisOptions() {
    const resp = await Request.get(`${_api}list/methodology-analysis`).catch(e => e);
    return resp;
  },

  async listTraderExperienceOptions() {
    const resp = await Request.get(`${_api}list/trader-experience`).catch(e => e);
    return resp;
  },

  async listOperationTypesOptions() {
    const resp = await Request.get(`${_api}list/operation-types`).catch(e => e);
    return resp;
  },

  async listSchoolingOptions() {
    const resp = await Request.get(`${_api}list/education`).catch(e => e);
    return resp;
  },

  async listBrokerOptions() {
    const resp = await Request.get(`${_api}list/brokers`).catch(e => e);
    return resp;
  },

  buildBody(data) {
    return {
      bTermsAndPrivacyPolicyRead: true,
      strBirthDate: data.dateOfBirth,
      strUserName: data.username,
      strSurname: data.usersurname,
      strUserEmail: data.email,
      strUserPassword: data.password,
      strUserDocument: data.document,
      strZipCode: data.cep,
      strAddress: data.address,
      strAddressNumber: data.number,
      strAddressComplement: data.complement,
      strNeighborhood: data.neighborhood,
      strCity: data.city,
      nState: data.state,
      nAddressCountry: data.nAddressCountry,
      nBillingCountry: data.nAddressCountry,
      createAccessToken: true,
      strRecaptchaResponse: data.strRecaptchaResponse,
      strCellPhone: data.cellphone,
      strMachineID: UserHelper.getHardwareID().toString(),
      nTypePlatformID,
      method,
    };
  },

  buildBodyUpdate(data) {
    return {
      strUserDocument: data.document,
      isCompany: data.isCompany,
      strUserName: data.firstName,
      strSurname: data.lastName,
      strBirthDate: data.dateOfBirth,
      strHomePhone: data.homePhone,
      strCellPhone: data.cellPhone,
      strCommercialPhone: data.commercialPhone,
      nGender: data.gender,
      nCorretoraID: data.nCorretoraID,
      nExperienciaMercadoID: data.nExperienciaMercadoID,
      nMetodologiaAnaliseAtivoID: data.nMetodologiaAnaliseAtivoID,
      nMetodologiaEstudoID: data.nMetodologiaEstudoID,
      strEscolaridade: data.strEscolaridade,
      strProfissao: data.strProfissao,
      listTipoInvestimento: data.listTipoInvestimento,
      strAddress: data.publicPlace,
      strAddressComplement: data.addressComplement,
      strAddressNumber: data.number,
      strCity: data.city,
      strNeighborhood: data.neighborhood,
      strZipCode: data.postCode,
      nState: data.state,
    };
  },
};

export default userApi;
